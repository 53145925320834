import { easeInOutQuad } from '~/utils/ease-in-out-quad'

/**
 * Скроллит страницу к указанному значению
 * @param {number} to
 * @param options options.duration
 * @param {number} options.offset
 * @param {number} options.duration
 */
export const scrollToPosition = (to = 0, {
  offset = 0,
  duration = 600,
  element = document.scrollingElement || document.documentElement,
} = {}) => {
  const toWithOffset = to + offset
  const start = element.scrollTop
  const change = toWithOffset - start
  const startDate = new Date().getTime()

  animateScroll()

  function animateScroll() {
    const currentDate = new Date().getTime()
    const currentTime = currentDate - startDate

    element.scrollTop = easeInOutQuad(currentTime, start, change, duration)

    if (currentTime < duration) {
      requestAnimationFrame(animateScroll)
    } else {
      element.scrollTop = toWithOffset
    }
  }
}
