/**
 * Quadratic easing in/out - Ускоряется до половины, потом замедляется
 * @param time
 * @param start
 * @param change
 * @param duration
 * @return {*}
 */
export const easeInOutQuad = (
  time: number,
  start: number,
  change: number,
  duration: number,
): number => {
  time /= duration / 2

  if (time < 1) {
    return change / 2 * time * time + start
  }

  time -= 1

  return -change / 2 * (time * (time - 2) - 1) + start
}
