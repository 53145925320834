import { defineStore } from 'pinia'

export type UseMainSnackbarOpenPayload = {
  text?: string
  timeout?: number
  cancelable?: boolean
  cancelHandler?: Function | undefined
  raised?: boolean
}

export const useSnackbarStore = defineStore('snackbar', () => {
  const isActive = ref(false)
  const text = ref('')
  const timeout = ref(4000)
  const cancelable = ref(false)
  const cancelHandler = ref<Function | undefined>(undefined)
  const raised = ref(false)

  function open(payload: UseMainSnackbarOpenPayload) {
    isActive.value = true
    text.value = payload.text ?? ''
    timeout.value = payload.timeout ?? timeout.value
    cancelable.value = payload.cancelable ?? cancelable.value
    cancelHandler.value = payload.cancelHandler
    raised.value = payload.raised ?? raised.value
  }

  function close() {
    isActive.value = false
    text.value = ''
    // timeout.value = 4000
    cancelable.value = false
    cancelHandler.value = undefined
    // raised.value = false
  }

  return {
    isActive,
    text,
    timeout,
    cancelable,
    cancelHandler,
    raised,
    open,
    close,
  }
})
